/* global Component, _ */
class filterProxyComponent extends Component {

    static name() {
        return "filterProxyComponent";
    }

    static componentName() {
        return "filterProxyComponent";
    }

    getProps() {
        return ['filterField', 'open_by_default'];
    }

    getComputed() {
        return {
            excludeCategories: function () {
                return this.filterField.ExcludeCategories ? this.filterField.ExcludeCategories.split(',') : [];
            },
            isExclude: function () {
                if(this.$store.getters.getCategorySelected && this.excludeCategories.length > 0) {
                    const paths = this.$store.getters.getCategorySelectedPath(this.$store.getters.getCategorySelected.code).map(x=>x.code);
                    const isExcludeCode = _.intersection(this.excludeCategories, paths);
                    if(isExcludeCode.length > 0)
                        return true;
                }
                return false;
            },
        };
    }

    getTemplate() {
        return `<div v-if="!isExclude">
                   <template v-if="filterField.FilterType=='Range_Number'" >
                        <rangeFilterComponent :filterField="filterField" :open_by_default="open_by_default"></rangeFilterComponent>
                   </template>
                   <template v-else-if="filterField.FilterType=='Classification_List'" >
                        <classificationFilterComponent :filterField="filterField" :open_by_default="open_by_default"></classificationFilterComponent>
                   </template>
                   <template v-else-if="filterField.FilterType=='Variant_List'" >
                        <variantsFilter :filterField="filterField" :open_by_default="open_by_default"></variantsFilter>
                   </template>
                   <template v-else-if="filterField.FilterType=='Value_List'" >
                        <valueFilterComponent :filterField="filterField" :open_by_default="open_by_default"></valueFilterComponent>
                   </template>
                   <template v-else-if="filterField.FilterType=='MultiValue_List'" >
                        <multiValueFilterComponent :filterField="filterField" :open_by_default="open_by_default"></multiValueFilterComponent>
                   </template>
                   <template v-else-if="filterField.FilterType=='Conditional_Filter'" >
                        <conditionalFilterComponent :filterField="filterField" :open_by_default="open_by_default"></conditionalFilterComponent>
                   </template>
                   <template v-else-if="filterField.FilterType=='Color_Filter'" >
                        <colorFilterComponent :filterField="filterField" :open_by_default="open_by_default"></colorFilterComponent>
                   </template>
                   <template v-else>
                        <p class="filter-title">filtro desconocido{{filterField.FilterType}}</p>
                   </template>
                </div>`;
    }
}

filterProxyComponent.registerComponent();